import React from 'react';
import { Icon, TooltipHost } from 'office-ui-fabric-react';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    style?: React.CSSProperties;
    text: string;
}

export default class InfoTooltipView extends React.PureComponent<Props> {

    private id: string = uuidv4();

    render() {
        return (
            <sup>
                <TooltipHost content={this.props.text} id={this.id} style={{ padding: "5px", lineHeight: "1.5" }}>
                    <Icon iconName="FeedbackRequestSolid" style={{ cursor: "help", fontSize: "16px", color: "#0D1D2C" }} />
                </TooltipHost>
            </sup>
        )
    }

}