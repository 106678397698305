import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Co2ContributionChart.css';
import _ from 'lodash';
import { GetScenarioComponentModel, GetProductModel, ComponentType } from '../../../api/Contracts';
import disabledDonut from '../disabled-donut.png';
import { Image } from 'office-ui-fabric-react';

type Props = {
    components: _.Dictionary<GetScenarioComponentModel>;
    product: GetProductModel;
}
type DonutComponent = {
    name: string;
    totalEmissions: number;
    componentType: ComponentType
};
export default class Co2ContributionChartContainer extends React.PureComponent<Props> {

    private calculateEmissions = (component: GetScenarioComponentModel) => {
        let totalWeight = this.props.product.quantity * this.props.product.weight;
        let emissions = 0;
        switch (component.type) {
            case ComponentType.Material:
                emissions = component.emissions.find(x => x.id === component.defaultEmissionId)!.value * totalWeight * (component.quantity / 100)
                break;
            case ComponentType.Transport:
                emissions = component.emissions.find(x => x.id === component.defaultEmissionId)!.value * totalWeight * component.quantity;
                break;
            default:
                emissions = component.emissions.find(x => x.id === component.defaultEmissionId)!.value * component.quantity;
                break;
        }
        return { name: component.name, totalEmissions: emissions, componentType: component.type }
    }

    private getRgbString = (input: { value: number, seriesIndex: number }) => {
        const components = this.getDonutComponentsFromProps();
        let element = _.find(components, x => x.totalEmissions === input.value)!;
        let subset = components.filter(x => x.componentType === element.componentType);
        let index = subset.indexOf(element);
        let opacity = (index + 1) / subset.length;

        switch (element.componentType) {
            case ComponentType.Material:
                return `rgba(0,143,251, ${opacity})`;
            case ComponentType.Transport:
                return `rgba(254,176,25, ${opacity})`;
            case ComponentType.Utility:
                return `rgba(0,227,150, ${opacity})`;
            default:
                console.error("Unrecognised ComponentType when deriving Rgb value");
                return `rgba(0,0,0, 1)`;
        }
    }

    private getDonutComponentsFromProps = (): DonutComponent[] => {
        return _.sortBy(_.map(this.props.components, x => this.calculateEmissions(x)).filter(x => x.totalEmissions > 0), x => x.componentType + x.name);
    }

    render() {
        let components = this.getDonutComponentsFromProps();
        if (components.length === 0) return <Image src={disabledDonut} />
        let options = {
            labels: components.map(x => x.name),
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                color: '#373D3F'
                            },
                            value: {
                                formatter: (value: number) => `${Math.round(value / 1000 * 100) / 100} tCO₂ eq`
                            },
                            total: {
                                show: true,
                                color: '#373D3F',
                                formatter: (w: any) => `${Math.round(w.globals.seriesTotals.reduce((a: number, b: number) => a + b, 0) / 1000 * 100) / 100} tCO₂ eq`
                            }
                        }
                    }
                }
            },
            tooltip: {
                enabled: false
            },
            legend: {
                show: false
            },
            colors: [(element: { value: number, seriesIndex: number }) => this.getRgbString(element)]
        };
        let series = components.map(x => x.totalEmissions);
        return <ReactApexChart options={options} series={series} type="donut" height="auto" width="80%" />;
    }
}