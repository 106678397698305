import React from 'react';
import InformationPageView from '../../components/information-page';

type Props = {
    className?: string;
    title: string;
    paragraphs: string[];
}

export default class WhatsNextView extends React.PureComponent<Props> {

    private title = "What's Next?";
    private paragraphs: string[] = [
        "See our <a href='https://www.cambridgeconsultants.com/expertise/sustainability'>Sustainability</a> page for examples of how Cambridge Consultants have provided product design and strategy value to our clients addressing sustainability and carbon market pressures.",
        "Or <a href='https://www.cambridgeconsultants.com/contact' target='_blank'>contact us</a> to see how these skills might be relevant to the product or process changes you’re identifying to keep your product competitive as carbon prices change. We’ve also included some of our more groundbreaking ideas that we believe would make a substantial impact while making good business sense - if one of these takes your interest please <a href='https://www.cambridgeconsultants.com/contact' target='_blank'>get in touch</a>.",
        "If you've got an idea for a product scenario, or an input or output to the tool that models your product decisions in more detail <a href='https://twitter.com/intent/tweet?text=%40CambConsultants+I%27ve+got+a+feature+suggestion%21&hashtags=UpInTheAir&url=https%3A%2F%2Fupintheair.cambridgeconsultants.com' target='_blank'>get in touch on Twitter</a>."
    ];

    render() {
        return <InformationPageView title={this.title} paragraphs={this.paragraphs} />;
    }
}