import React from 'react';
import { IContextualMenuProps, IContextualMenuItem, TextField, PrimaryButton } from 'office-ui-fabric-react';

type Props = {
    textField: {
        prefix?: string;
        suffix?: string;
        label?: string;
        required?: boolean;
        defaultValue?: string;
        onGetErrorMessage: (value: string) => string | JSX.Element | PromiseLike<string | JSX.Element> | undefined;
    }
    onValueChanged: (value: string) => void;
    button: {
        text?: string;
        menuProps: IContextualMenuProps;
    }
}

type State = {
    value?: string;
}

export default class SuggestionTextField extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            value: props.textField.defaultValue
        };
    }

    private onNotifyValidationResult = (errorMessage: string | JSX.Element, value: string | undefined): void => {
        if (errorMessage) return;
        if (value === undefined) return;
        this.setState({ value: value });
        this.props.onValueChanged(value);
    };

    private onItemClicked = (ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, item?: IContextualMenuItem): boolean | void => {
        if (item === undefined || item.data === undefined) return;
        this.setState({ value: item.data });
    };

    private onChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
        this.setState({ value: newValue });
    };

    render() {
        return (
            <>
                <TextField prefix={this.props.textField.prefix} suffix={this.props.textField.suffix} label={this.props.textField.label} required={this.props.textField.required} value={this.state.value} onChange={this.onChange} onGetErrorMessage={this.props.textField.onGetErrorMessage} onNotifyValidationResult={this.onNotifyValidationResult} />
                <PrimaryButton text={this.props.button.text} menuProps={{ ...this.props.button.menuProps, onItemClick: this.onItemClicked }} />
            </>
        );
    }
}