import './index.css';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './components/app';
import { initializeIcons } from '@uifabric/icons';
import { ScreenClassProvider } from 'react-grid-system';

if (process.env.NODE_ENV !== "development" && window.location.protocol !== "https:") {
    window.location.protocol = "https:";
}

initializeIcons();

ReactDOM.render(
    <ScreenClassProvider>
        <App />
    </ScreenClassProvider>,
    document.getElementById("app")
);

serviceWorker.unregister();