import React from 'react';
import { GetComponentModel, GetScenarioComponentModel, ComponentType } from '../../api/Contracts';
import { getComponentsByTypeAsync } from '../../api/Endpoints';
import ComponentListView, { ComponentCallbacks, ComponentListViewModel } from '../components-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Toggle, IColumn, Text, TextField } from 'office-ui-fabric-react';
import { IconName } from '@fortawesome/fontawesome-common-types';

export type Params = {
    activeComponents: GetScenarioComponentModel[],
    onComponentUpdated: (component: GetScenarioComponentModel) => void;
    onComponentSelected: (components: GetComponentModel[]) => void;
    componentRenderKey: string;
}

type State = {
    utilities: GetComponentModel[];
};

export default class UtilitiesListContainer extends React.PureComponent<Params, State> {

    constructor(props: Params) {
        super(props);
        this.state = {
            utilities: []
        }
    }

    private columnFactory = (componentRenderKey: string) => (callbacks: ComponentCallbacks): IColumn[] => {
        return [{
            key: "Utilities",
            name: "Utilities",
            minWidth: 80,
            onRender: (item: ComponentListViewModel) => <Text key={item.id}><FontAwesomeIcon icon={item.icon.name as IconName} style={{ color: item.icon.color }} /> {item.name}</Text>
        }, {
            key: "Renewable",
            name: "Renewable",
            minWidth: 80,
            onRender: (item: ComponentListViewModel) => item.emissions.every(x => x.value === item.emissions[0].value) ? <div /> : <Toggle key={item.id} onChange={callbacks.onEmissionsChanged(item)} />
        }, {
            key: "Consumption",
            name: "Consumption",
            minWidth: 90,
            onRender: (item: ComponentListViewModel) => <TextField key={componentRenderKey} onChange={callbacks.onQuantityChanged(item)} max={item.maxQuantity} step={item.maxQuantity / 100} defaultValue={item.quantity.toString()} suffix={item.unit} />
        }];
    }

    async componentDidMount() {
        try {
            let response = await getComponentsByTypeAsync(ComponentType.Utility);
            this.setState({ utilities: response.components });
        } catch (ex) {
            console.log(ex);
        }
    }

    render() {
        if (this.state.utilities.length === 0) return <div />;
        return <ComponentListView
            allComponents={this.state.utilities}
            selectedComponents={this.props.activeComponents}
            columnFactory={this.columnFactory(this.props.componentRenderKey)}
            onComponentUpdated={this.props.onComponentUpdated}
            onComponentSelected={this.props.onComponentSelected}
            maxValue={10000} />
    }
}