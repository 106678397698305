import * as React from 'react';
import { IButtonProps, IComponentAsProps, Text, Icon } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';

export default class NavLinkView extends React.PureComponent<IComponentAsProps<IButtonProps>> {

    render() {
        if (this.props.href === undefined) throw new Error("href for NavLink was undefined");
        return (
            <Link className={this.props.className} to={this.props.href}>
                <Icon {...this.props.iconProps} />
                <Text>{this.props.title}</Text>
            </Link>
        );
    }
}