import React from 'react';
import ScenarioView from '../../routes/scenario/ScenarioView'
import { RouteComponentProps } from 'react-router';
import { GetScenarioModel, GetComponentModel, InstructionType, GetInstructionModel } from '../../api/Contracts';
import { getScenariosAsync, getComponentsAsync, getInstructionsByTypeAsync } from '../../api/Endpoints';
import _ from 'lodash';

export type Params = {
    id: string;
}

type State = {
    scenario: GetScenarioModel | undefined;
    components: GetComponentModel[] | undefined;
    instructions: GetInstructionModel[] | undefined;
};

export default class TutorialContainer extends React.PureComponent<{}, State> {

    private scenarios: GetScenarioModel[] = [];

    constructor(props: RouteComponentProps<Params>) {
        super(props);
        this.state = { scenario: undefined, components: undefined, instructions: undefined };
    }

    async componentDidMount() {
        try {
            let [scenariosResponse, componentResponse, instructionsResponse] = await Promise.all([
                getScenariosAsync(),
                getComponentsAsync(),
                getInstructionsByTypeAsync(InstructionType.Tutorial)
            ]);
            this.scenarios = scenariosResponse.scenarios;
            const instructions = _.orderBy(instructionsResponse.instructions, x => x.target);
            this.setState({ scenario: scenariosResponse.scenarios.find(x => x.id === instructions[0].scenarioId), components: componentResponse.components, instructions: instructionsResponse.instructions });
        } catch (ex) {
            console.error(ex);
        }
    }

    onChangeScenario = (scenarioId: string): void => {
        this.setState({ scenario: this.scenarios.find(x => x.id === scenarioId)! });
    }

    render() {
        if (this.state.scenario === undefined || this.state.components === undefined || this.state.instructions === undefined) return null;
        return (
            <div>
                <ScenarioView scenario={this.state.scenario} components={this.state.components} onChangeScenario={this.onChangeScenario} instructions={this.state.instructions} />
            </div>
        );
    }
}