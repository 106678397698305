import React from 'react';
import { HashRouter } from 'react-router-dom';
import Sidebar from '../sidebar';
import Content from '../content';
import './App.css';
import * as Grid from 'react-grid-system';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import GoogleAnalytics from '../google-analytics';
import * as ReactGA from 'react-ga';
import MobileNav from '../mobile-nav';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE!, { gaOptions: { siteSpeedSampleRate: 100 } });
library.add(fas);

const fullHeight = {
    minHeight: "100%",
    height: "100%"
}

const noPadding = {
    paddingLeft: 0,
    paddingRight: 0
}


export default class App extends React.PureComponent {

    render() {
        return (
            <HashRouter>
                <Grid.Container fluid={true} style={fullHeight}>
                    <Grid.Row style={fullHeight}>
                        <Grid.Visible xl>
                            <Grid.Col xl={2} style={noPadding}>
                                <Sidebar className="sidebar" />
                            </Grid.Col>
                        </Grid.Visible>
                        <Grid.Col xl={10} lg={12} style={noPadding}>
                            <MobileNav></MobileNav>
                            <Content className="content" />
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Container>
                <GoogleAnalytics />
            </HashRouter >
        );
    }
}
