import React from 'react';
import './Panel.css';
import { CSSTransition } from 'react-transition-group';

type Props = {
    style?: React.CSSProperties
}

export default class PanelView extends React.PureComponent<Props> {

    render() {
        return (
            <CSSTransition in={true} classNames="panel" timeout={50} appear={true}>
                <div className="panel" style={this.props.style}>
                    {this.props.children}
                </div>
            </CSSTransition>
        );
    }

}