import React, { FormEvent } from "react";
import './SettingsMenu.css';
import { Panel, PanelType, Stack, IContextualMenuProps, Dropdown, IDropdownOption, TextField, Text } from 'office-ui-fabric-react';
import { v4 as uuidv4 } from 'uuid';
import SuggestionTextField from "../suggestion-text-field";
import { GetProductModel, SequestrationPricingModel } from "../../api/Contracts";

type Props = {
    isOpen: boolean;
    onClose: (ev?: React.SyntheticEvent<HTMLElement>) => void;
    callbacks: SettingsCallbacks;
    emissionCosts: {
        current: number;
        future: number;
    };
    sequestration: {
        pricingModel: SequestrationPricingModel;
        price: number;
        permanentCutoff: number;
    };
    product: GetProductModel;
}

export type SettingsCallbacks = {
    onCurrentEmissionCostChanged: (value: string) => void;
    onFutureEmissionCostChanged: (value: string) => void;
    onSequestrationPriceChanged: (value: string) => void;
    onProductCostChanged: (errorMessage: string | JSX.Element, value: string | undefined) => void;
    onProductLifespanChanged: (errorMessage: string | JSX.Element, value: string | undefined) => void;
    onProductWeightChanged: (errorMessage: string | JSX.Element, value: string | undefined) => void;
    onProductQuantityChanged: (errorMessage: string | JSX.Element, value: string | undefined) => void;
    onSequestrationPricingModelChanged: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined) => void;
    isNaNError: (value: string) => string;
}

type State = {
    sequestrationPriceValidationMessage: string;
}

export default class SettingsMenuView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
             sequestrationPriceValidationMessage: SettingsMenuView.getSequestrationPriceValidationMessage(props)
        };
    }

    private static getSequestrationPriceValidationMessage = (props: Props): string => {
        if(props.sequestration.pricingModel === SequestrationPricingModel.Permanent && props.product.lifespan < props.sequestration.permanentCutoff){
            return "Your product lifespan is too short to benefit from permanent sequestration pricing policies. Try using tonne-year pricing or increasing your product lifespan to at least " + props.sequestration.permanentCutoff + " years.";
        }
        return "";
    }

    static mapPropsToState(props: Props): State {
        return {
            sequestrationPriceValidationMessage: SettingsMenuView.getSequestrationPriceValidationMessage(props)
        };
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        this.setState(SettingsMenuView.mapPropsToState(props));
    }

    private sequestrationPrices: IContextualMenuProps = {
        items: [{
            key: uuidv4(),
            text: "Kyoto CDM - $0.2 / tonne",
            data: 0.2
        }, {
            key: uuidv4(),
            text: "Gold Standard - $4.5 / tonne",
            data: 4.5
        }, {
            key: uuidv4(),
            text: "puro.earth - $25 / tonne",
            data: 25
        }]
    }

    private emissionCosts: IContextualMenuProps = {
        items: [{
            key: uuidv4(),
            text: "Shanghai Pilot ETS - $6 / tonne",
            data: 6
        }, {
            key: uuidv4(),
            text: "California CaT - $17 / tonne",
            data: 17
        }, {
            key: uuidv4(),
            text: "EU ETS - $28 / tonne",
            data: 28
        }]
    }

    private targetCosts: IContextualMenuProps = {
        items: [{
            key: uuidv4(),
            text: "Floor Price - $160 / tonne",
            data: 160
        }, {
            key: uuidv4(),
            text: "Central Price - $210 / tonne",
            data: 210
        }, {
            key: uuidv4(),
            text: "Ceiling Price - $390 / tonne",
            data: 390
        }]
    }

    private options: IDropdownOption[] = [{
        key: SequestrationPricingModel.None,
        data: SequestrationPricingModel.None,
        text: "None"
    }, {
        key: SequestrationPricingModel.Linear,
        data: SequestrationPricingModel.Linear,
        text: "Tonne-Year Sequestration"
    }, {
        key: SequestrationPricingModel.Permanent,
        data: SequestrationPricingModel.Permanent,
        text: "Permanent Sequestration"
    }];

    render() {
        return (
            <Panel isOpen={this.props.isOpen} isLightDismiss={true} type={PanelType.smallFixedNear} onDismiss={this.props.onClose}>
                <Stack verticalFill={true} tokens={{ childrenGap: 10 }} verticalAlign="center">
                <Text as="h2" variant="large">CO₂ Settings</Text>
                    <SuggestionTextField
                        textField={{ prefix: "$", suffix: "/ tonne", label: "2020 CO₂ sequestration price", required: true, defaultValue: (this.props.sequestration.price * 1000).toString(), onGetErrorMessage: this.props.callbacks.isNaNError }}
                        onValueChanged={this.props.callbacks.onSequestrationPriceChanged}
                        button={{ text: "Sequestration Prices", menuProps: this.sequestrationPrices }} />
                    <SuggestionTextField
                        textField={{ prefix: "$", suffix: "/ tonne", label: "2020 CO₂eq emission cost", required: true, defaultValue: (this.props.emissionCosts.current * 1000).toString(), onGetErrorMessage: this.props.callbacks.isNaNError }}
                        onValueChanged={this.props.callbacks.onCurrentEmissionCostChanged}
                        button={{ text: "Emission Costs", menuProps: this.emissionCosts }} />
                    <SuggestionTextField
                        textField={{ prefix: "$", suffix: "/ tonne", label: "2050 CO₂eq emission cost", required: true, defaultValue: (this.props.emissionCosts.future * 1000).toString(), onGetErrorMessage: this.props.callbacks.isNaNError }}
                        onValueChanged={this.props.callbacks.onFutureEmissionCostChanged}
                        button={{ text: "Target Costs", menuProps: this.targetCosts }} />
                    <Dropdown label="Sequestration pricing models" options={this.options} errorMessage={this.state.sequestrationPriceValidationMessage} defaultSelectedKey={this.props.sequestration.pricingModel} onChange={this.props.callbacks.onSequestrationPricingModelChanged} />
                </Stack>
                <br />
                <Stack verticalFill={true} tokens={{ childrenGap: 10 }} verticalAlign="center">                    
                    <Text as="h2" variant="large">Product Settings</Text>
                    <TextField suffix="kg" label="Product weight" required={true} defaultValue={this.props.product.weight.toString()} onGetErrorMessage={this.props.callbacks.isNaNError} onNotifyValidationResult={this.props.callbacks.onProductWeightChanged} />
                    <TextField suffix="units" label="Product quantity" required={true} defaultValue={this.props.product.quantity.toString()} onGetErrorMessage={this.props.callbacks.isNaNError} onNotifyValidationResult={this.props.callbacks.onProductQuantityChanged} />
                    <TextField prefix="$" label="Product cost" required={true} defaultValue={this.props.product.cost.toString()} onGetErrorMessage={this.props.callbacks.isNaNError} onNotifyValidationResult={this.props.callbacks.onProductCostChanged} />
                    <TextField suffix="years" label="Product lifespan" required={true} defaultValue={this.props.product.lifespan.toString()} onGetErrorMessage={this.props.callbacks.isNaNError} onNotifyValidationResult={this.props.callbacks.onProductLifespanChanged} />
                </Stack>
            </Panel>
        );
    }
}