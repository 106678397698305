import React from 'react';
import { Text, IColumn, Toggle, TextField } from 'office-ui-fabric-react';
import { GetScenarioComponentModel, GetComponentModel, ComponentType, ComponentEmissionSource } from '../../api/Contracts';
import { getComponentsByTypeAsync } from '../../api/Endpoints';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ComponentListView, { ComponentCallbacks, ComponentListViewModel } from "../components-list";
import { IconName } from '@fortawesome/fontawesome-common-types';

export type Props = {
    activeComponents: GetScenarioComponentModel[],
    onComponentUpdated: (component: GetScenarioComponentModel) => void;
    onComponentSelected: (components: GetComponentModel[]) => void;
    componentRenderKey: string;
}

type State = {
    materials: GetComponentModel[];
};

export default class MaterialsListContainer extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            materials: []
        }
    }

    private getMaterialQuantity = (): number => this.props.activeComponents.filter(x => x.type === ComponentType.Material).map(x => x.quantity).reduce((previous, current) => previous + current, 0)

    private columnFactory = (componentRenderKey: string) => (callbacks: ComponentCallbacks): IColumn[] =>
        [{
            key: "Materials",
            name: "Materials",
            minWidth: 80,
            onRender: (item: ComponentListViewModel) => <Text key={item.id}><FontAwesomeIcon icon={item.icon.name as IconName} style={{ color: item.icon.color }} /> {item.name}</Text>
        }, {
            key: "Recycled",
            name: "Recycled",
            minWidth: 80,
            onRender: (item: ComponentListViewModel) => item.emissions.every(x => x.value === item.emissions[0].value) ? <div /> : <Toggle key={item.id} onChange={callbacks.onEmissionsChanged(item)} checked={item.defaultEmissionId === item.emissions.find(x => x.source === ComponentEmissionSource.Renewable)!.id} />
        }, {
            key: "Quantity",
            name: `Quantity (${this.getMaterialQuantity()}%)`,
            minWidth: 95,
            //onRender: (item: ComponentListViewModel) => <div key={componentRenderKey}><Slider key={item.id} onChange={callbacks.onQuantityChanged(item)} max={item.maxQuantity} step={item.maxQuantity / 100} defaultValue={item.quantity} valueFormat={item.valueFormat} showValue={true} /></div>,
            onRender: (item: ComponentListViewModel) => <div key={componentRenderKey}><TextField key={item.id} onChange={callbacks.onQuantityChanged(item)} max={item.maxQuantity} step={item.maxQuantity / 100} defaultValue={item.quantity.toString()} suffix={item.unit} /></div>
        }];

    async componentDidMount() {
        try {
            let response = await getComponentsByTypeAsync(ComponentType.Material);
            this.setState({ materials: response.components });
        } catch (ex) {
            console.error(ex);
        }
    }

    render() {
        if (this.state.materials.length === 0) return <div />
        return <ComponentListView
            allComponents={this.state.materials}
            selectedComponents={this.props.activeComponents}
            columnFactory={this.columnFactory(this.props.componentRenderKey)}
            onComponentUpdated={this.props.onComponentUpdated}
            onComponentSelected={this.props.onComponentSelected}
            maxValue={100} />
    }
}