import React from "react";
import NavView from "./NavView";
import { INavLink } from "office-ui-fabric-react";
import { getScenariosAsync } from "../../api/Endpoints";
import _ from 'lodash';

type Props = {
    onClick?: () => void;
    hidden?: boolean;
}

type State = {
    routes: INavLink[]
}


export default class NavContainer extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            routes: []
        };
    }

    async componentDidMount() {
        try {
            let scenariosResponse = await getScenariosAsync();
            let scenarios = _.sortBy(scenariosResponse.scenarios, s => s.id !== "cfc8b433-cd13-4586-2f80-08d75e1c8846");
            this.setState({
                routes: [{
                    name: "Home",
                    url: "/",
                    key: "/"
                },
                {
                    name: "Tutorial",
                    url: "/tutorial",
                    key: "/tutorial"
                },
                // {
                //     name: "Key Messages",
                //     url: "/key-messages",
                //     key: "/key-messages"
                // },
                {
                    name: "Scenarios",
                    url: "/scenarios",
                    key: "/scenarios",
                    isExpanded: true,
                    links: scenarios.map<INavLink>(x => ({
                        name: x.name,
                        url: `/scenario/${x.id}`,
                        key: `/scenario/${x.id}`
                    }))
                },
                {
                    name: "What's Next?",
                    url: "/whats-next",
                    key: "/whats-next"
                },
                {
                    name: "About This Tool",
                    url: "/about",
                    key: "/about"
                },
                {
                    name: "Privacy Policy",
                    url: "/privacy-policy",
                    key: "/privacy-policy"
                }]
            });
        } catch (ex) {
            console.error(ex);
        }
    }

    render() {
        if (this.props.hidden) return null;
        return <NavView routes={this.state.routes} onClick={this.props.onClick} />;
    }
}


