import React from 'react';

type Props = {
    delay: number;
}
type State = {
    hidden: boolean;
}
export default class DelayView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { hidden: true };
    }

    componentDidMount() {
        setTimeout(() => this.setState({ hidden: false }), this.props.delay);
    }

    render() {
        if (this.state.hidden) return null;
        return this.props.children;
    }
}