import React from 'react';
import ShareDialogView from './ShareDialogView';
import { GetScenarioComponentModel, CreateScenarioComponentModel, GetScenarioModel, GetProductModel, CreateScenarioModel, SequestrationPricingModel } from '../../api/Contracts';

type Props = {
    components: GetScenarioComponentModel[];
    scenario: GetScenarioModel;
    sequestration: {
        pricingModel: SequestrationPricingModel;
        price: number;
    };
    emissionCosts: {
        current: number;
        future: number;
    };
    product: GetProductModel;
    hidden: boolean;
    onDismiss: (ev?: React.MouseEvent<HTMLButtonElement>) => void;
}

export default class ShareDialogContainer extends React.PureComponent<Props> {

    render() {
        const components: CreateScenarioComponentModel[] = this.props.components.map(c => ({ componentId: c.id, emissionId: c.defaultEmissionId, quantity: c.quantity }));
        const createScenarioModel: CreateScenarioModel = {
            currentEmissionCost: this.props.emissionCosts.current,
            futureEmissionCost: this.props.emissionCosts.future,
            description: this.props.scenario.description,
            name: this.props.scenario.name,
            product: this.props.product,
            sequestrationPrice: this.props.sequestration.price,
            sequestrationPricingModel: this.props.sequestration.pricingModel,
            components: components
        }
        return <ShareDialogView scenario={createScenarioModel} hidden={this.props.hidden} onDismiss={this.props.onDismiss} />
    }
}