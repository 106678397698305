import React from 'react';
import { GetScenarioOptionModel, InstructionTarget } from '../../api/Contracts';
import { CompoundButton } from 'office-ui-fabric-react';
import Panel from '../../components/panel';
import * as Grid from 'react-grid-system';

type Props = {
    style?: React.CSSProperties;
    scenarioOptions: GetScenarioOptionModel[];
    onOptionSelected: (option: GetScenarioOptionModel) => void;
}

export default class ScenarioOptionsView extends React.PureComponent<Props> {

    render() {
        if (this.props.scenarioOptions.length === 0) return <div />
        return (
            <div id={InstructionTarget[InstructionTarget.ScenarioOptions]} style={{ width: "100%" }}>
                <Grid.Col md={12}>
                    <Panel style={{ marginBottom: 0, paddingBottom: 0 }}>
                        <Grid.Row>
                            {this.props.scenarioOptions.map(option =>
                                <Grid.Col key={option.id} xl={3} lg={4} sm={12} style={{ marginBottom: 10 }}>
                                    <CompoundButton key={option.id} iconProps={{ iconName: option.icon.name }} secondaryText={option.description} onClick={() => this.props.onOptionSelected(option)} style={{ maxWidth: "none", width: "100%", height: "100%" }}>
                                        {option.name}
                                    </CompoundButton>
                                </Grid.Col>
                            )}
                        </Grid.Row>
                    </Panel>
                </Grid.Col>
                <Grid.Col md={12} style={{ height: 15 }} />
            </div >
        )
    }

}