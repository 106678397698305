import React from "react";
import './ComponentSelector.css';
import _ from 'lodash';
import { Panel, PanelType, Stack, DefaultButton } from 'office-ui-fabric-react';
import { GetComponentModel } from "../../api/Contracts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconName } from "@fortawesome/fontawesome-svg-core";

type Props = {
    isOpen: boolean;
    headerText: string;
    components: GetComponentModel[];
    onComponentClicked: (component: GetComponentModel) => void;
    onClose: (ev?: React.SyntheticEvent<HTMLElement>) => void;
}

type State = {
    isOpen: boolean;
}

export default class ComponentSelectorView extends React.PureComponent<Props, State> {

    render() {
        return (
            <Panel isOpen={this.props.isOpen} isLightDismiss={true} type={PanelType.smallFixedNear} headerText={this.props.headerText} onDismiss={this.props.onClose}>
                <Stack verticalFill={true} tokens={{ childrenGap: 10 }} horizontalAlign="center" verticalAlign="center">
                    {_.sortBy(this.props.components, x => x.name).map(x =>
                        <DefaultButton key={x.id} style={{ width: "100%" }} onClick={() => this.props.onComponentClicked(x)}>
                            <FontAwesomeIcon icon={x.icon.name as IconName} style={{ color: x.icon.color }} />&nbsp;&nbsp;{x.name}
                        </DefaultButton>
                    )}
                </Stack>
            </Panel>
        );
    }
}