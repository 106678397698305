import React from 'react';
import './Sidebar.css';
import { Stack, StackItem } from 'office-ui-fabric-react';
import Nav from '../nav';
import CambridgeConsultantsLogo from '../ccl-logo';
import UpInTheAirLogo from '../upintheair-logo';

interface Props {
    className?: string;
}

export default class SidebarView extends React.PureComponent<Props> {
    render() {
        return (
            <Stack id="Sidebar" verticalFill verticalAlign="space-between" className={this.props.className}>
                <Stack className="header" verticalAlign="center">
                    <UpInTheAirLogo className="uita-logo" />
                    <div style={{ height: 15 }} />
                    <Nav />
                </Stack>
                <StackItem className="footer" align="end">
                    <CambridgeConsultantsLogo className="ccl-logo" />
                </StackItem>
            </Stack>
        );
    }
}