import React from 'react';
import * as Fabric from 'office-ui-fabric-react';
import NavLink from '../nav-link';
import { INavLink } from 'office-ui-fabric-react';

type Props = {
    routes: INavLink[];
    onClick?: () => void;
}

type State = {
    selectedKey: string;
}

export default class NavView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { selectedKey: window.location.hash.substring(1) }
        window.onhashchange = this.onHashChange;
    }

    onHashChange = () => this.setState({ selectedKey: window.location.hash.substring(1) });

    render() {
        return (
            <span onClick={this.props.onClick}>
                <Fabric.Nav
                    linkAs={NavLink}
                    groups={[{ links: this.props.routes }]}
                    selectedKey={this.state.selectedKey}
                />
            </span>
        );
    }
}