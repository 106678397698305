import React from 'react';
import * as Grid from 'react-grid-system';
import UpInTheAirLogo from '../upintheair-logo';
import { DefaultButton } from 'office-ui-fabric-react';
import './MobileNavView.css';
import NavContainer from '../nav';

export default class MobileNavView extends React.PureComponent<{}, State> {

    constructor(props: {}) {
        super(props);
        this.state = { toggled: false }
    }

    private onClick = (): void => {
        const toggled = !this.state.toggled;
        this.setState({ toggled: toggled });
    }

    render() {
        return (
            <Grid.Hidden xl>
                <div className="mobile-nav">
                    <Grid.Row style={{ paddingTop: 15, paddingBottom: 15 }}>
                        <Grid.Col xs={4}>
                            <MenuButton onClick={this.onClick} toggled={this.state.toggled} />
                        </Grid.Col>
                        <Grid.Col xs={4}>
                            <UpInTheAirLogo width="100%" />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col xs={12}>
                            <NavContainer onClick={this.onClick} hidden={!this.state.toggled} />
                        </Grid.Col>
                    </Grid.Row>
                </div>
            </Grid.Hidden >
        );
    }
}

type Props = {
    onClick: () => void;
    toggled: boolean;
};
type State = {
    toggled: boolean;
};

class MenuButton extends React.PureComponent<Props> {
    render() {
        const iconName = this.props.toggled ? "Cancel" : "CollapseMenu";
        return (
            <DefaultButton toggle checked={this.props.toggled} iconProps={{ iconName: iconName }} onClick={this.props.onClick} style={{ height: "100%", border: "none", fontSize: "20px" }} />
        );
    }
}