import React from 'react';
import InformationPageView from '../../components/information-page';

type Props = {
    className?: string;
    title: string;
    paragraphs: string[];
}

export default class AboutView extends React.PureComponent<Props> {

    private title = "About This Tool";
    private paragraphs: string[] = [
        "We designed this online modelling tool as an internal investment at Cambridge Consultants, to help us engage with our clients on what’s important to them, while demonstrating some of our software modelling, UX capabilities and domain knowledge.",
        "We are following an agile sprint process with regular feedback from internal and external sources, and for the technology stack we’ve used Microsoft Azure, Gitlab CI and CD, Node.js and React. We can also collect aggregated data to improve how the tool works and generate insights and datasets suitable for AI ingestion. If collecting real world data is your blocker for AI investment or you want more interaction with your customers, get in touch to see how we can help using online modelling.",
        "To gather the data underlying the model we’ve reviewed sources and research from the Carbon Disclosure Project, the World Bank, the Climate Reality Project, Carbon Pricing Unlocked, DEFRA, BEIS, PAS2050, the the Greenhouse Gas (GHG) protocol, ISO14062 and ISO14067, The EC Institute for Environment and Sustainability, Comparison of Product Carbon Footprint Protocols, the IPCC, Science Based Targets, the Royal Society, the Carbon Trust, the World Resources Institute, the Grantham Research Institute, the Royal Academy of Engineering, Transition Pathway Initiative, Smartest Energy, the Global Calculator, and the International Energy Agency, and aimed to distill these into the a model that is both easy to use and relevant for decision makers that want to understand carbon impact."
    ];

    render() {
        return <InformationPageView title={this.title} paragraphs={this.paragraphs} />;
    }
}