import React from 'react';
import { GetScenarioModel } from '../../api/Contracts';
import { getScenariosAsync } from '../../api/Endpoints';
import ScenariosView from './ScenariosView';

type State = {
    scenarios: GetScenarioModel[]
}

type Props = {}

export default class ScenariosContainer extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            scenarios: []
        };
    }

    async componentDidMount() {
        try {
            const response = await getScenariosAsync();
            this.setState({ scenarios: response.scenarios });
        }
        catch (error) {
            console.error(error);
        }
    }

    render() {
        if (this.state.scenarios.length === 0) return null;
        return <ScenariosView scenarios={this.state.scenarios} />;
    }
}