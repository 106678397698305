import React from 'react';
import * as ReactGA from 'react-ga';
import { RouteComponentProps, withRouter } from 'react-router';

class GoogleAnalyticsView extends React.Component<RouteComponentProps> {

    private trackPage = (page: string) => {
        ReactGA.set({ page });
        ReactGA.pageview(page);
    };

    componentDidMount() {
        const page = this.props.location.pathname + this.props.location.search;
        this.trackPage(page);
    }

    componentDidUpdate(prevProps: RouteComponentProps) {
        const currentPage = prevProps.location.pathname + prevProps.location.search;
        const nextPage = this.props.location.pathname + this.props.location.search;

        if (currentPage !== nextPage) {
            this.trackPage(nextPage);
        }
    }

    render() {
        return null;
    }
}

export default withRouter(GoogleAnalyticsView);