import React, { FormEvent } from 'react';
import { GetScenarioComponentModel, GetProductModel, GetScenarioModel, GetScenarioOptionModel, GetComponentModel, ComponentType, ComponentEmissionSource, InstructionTarget, GetInstructionModel, SequestrationPricingModel } from '../../api/Contracts';
import './Scenario.css';
import { Text, CommandBar, ICommandBarItemProps, Separator, ActionButton, IDropdownOption } from 'office-ui-fabric-react';
import Co2CostChart from '../../components/charts/co2-cost-chart';
import Co2ContributionChart from '../../components/charts/co2-contribution-chart';
import CarbonContentChart from '../../components/charts/carbon-content-chart';
import Panel from '../../components/panel';
import * as Grid from 'react-grid-system';
import MaterialsListContainer from '../../components/materials-list';
import UtilitiesListContainer from '../../components/utilities-list';
import TransportListContainer from '../../components/transport-list';
import _ from 'lodash';
import ComponentSelector from '../../components/component-selector';
import SettingsMenu, { SettingsCallbacks } from '../../components/settings-menu';
import ScenarioOptionsView from '../../components/scenario-options';
import { v4 as uuidv4 } from 'uuid';
import InfoTooltipView from '../../components/info-tooltip';
import ShareDialog from '../../components/share-dialog';
import InstructionBubbles from '../../components/instruction-bubbles';
import Delay from '../../components/delay';

type Props = {
    className?: string;
    scenario: GetScenarioModel;
    components: GetComponentModel[];
    onChangeScenario: (scenarioId: string) => void;
    instructions: GetInstructionModel[];
}

type State = {
    activeComponents: _.Dictionary<GetScenarioComponentModel>;
    product: GetProductModel;
    emissionCosts: {
        current: number;
        future: number;
    };
    componentSelectorState: {
        [type in ComponentType]: {
            isOpen: boolean;
        }
    };
    settingsMenuState: {
        isOpen: boolean;
    };
    sequestration: {
        pricingModel: SequestrationPricingModel;
        price: number;
    };
    componentRenderKey: string;
    shareDialog: {
        hidden: boolean;
    };
}

export default class ScenarioView extends React.PureComponent<Props, State> {

    private permanentSequestrationCutoff: number = 50;

    private openSettingsMenu = () => this.setState({ settingsMenuState: { isOpen: true } });

    private onSettingsMenuClosed = (ev?: React.SyntheticEvent<HTMLElement>) => this.setState({ settingsMenuState: { isOpen: false } });

    private leftCommands: ICommandBarItemProps[] = [
        {
            key: "Add",
            name: "Add",
            iconProps: {
                iconName: "Add"
            },
            subMenuProps: {
                items: [
                    {
                        key: "Materials",
                        name: "Materials",
                        iconProps: {
                            iconName: "Repair"
                        },
                        onClick: () => this.setState({ componentSelectorState: { ...this.state.componentSelectorState, [ComponentType.Material]: { isOpen: true } } })
                    },
                    {
                        key: "Utilities",
                        name: "Utilities",
                        iconProps: {
                            iconName: "LightningBolt"
                        },
                        onClick: () => this.setState({ componentSelectorState: { ...this.state.componentSelectorState, [ComponentType.Utility]: { isOpen: true } } })
                    },
                    {
                        key: "Transport",
                        name: "Transport",
                        iconProps: {
                            iconName: "World"
                        },
                        onClick: () => this.setState({ componentSelectorState: { ...this.state.componentSelectorState, [ComponentType.Transport]: { isOpen: true } } })
                    }
                ]
            }
        },
        {
            key: 'Remove',
            text: 'Remove',
            iconProps: { iconName: 'Delete' },
            onClick: () => {
                let activeComponents = this.state.activeComponents;
                let idsToRemove = _.map(this.selectedComponents, x => x.id);
                this.selectedComponents = {};
                this.setState({ activeComponents: _.omitBy(activeComponents, x => idsToRemove.includes(x.id)) });
            }
        },
        {
            key: 'Share',
            name: 'Save & Share',
            iconProps: {
                iconName: 'Share'
            },
            onClick: () => this.setState({ shareDialog: { hidden: false } })
        },
        {
            key: 'Reset',
            name: 'Reset Scenario',
            iconProps: {
                iconName: 'Refresh'
            },
            onClick: () => this.setState(ScenarioView.mapPropsToState(this.props))
        }
    ];

    private rightCommands: ICommandBarItemProps[] = [
        {
            key: "Settings",
            name: "Settings",
            iconProps: {
                iconName: "Settings"
            },
            onClick: this.openSettingsMenu
        }
    ];

    static mapPropsToState(props: Props): State {
        return {
            activeComponents: ScenarioView.reduceComponents(props.scenario.components),
            product: props.scenario.product,
            emissionCosts: {
                current: props.scenario.currentEmissionCost,
                future: props.scenario.futureEmissionCost,
            },
            componentSelectorState: {
                [ComponentType.None]: { isOpen: false },
                [ComponentType.Material]: { isOpen: false },
                [ComponentType.Transport]: { isOpen: false },
                [ComponentType.Utility]: { isOpen: false }
            },
            settingsMenuState: {
                isOpen: false
            },
            componentRenderKey: uuidv4(),
            shareDialog: { hidden: true },
            sequestration: {
                pricingModel: props.scenario.sequestrationPricingModel,
                price: props.scenario.sequestrationPrice
            }
        }
    }

    constructor(props: Props) {
        super(props);
        this.state = ScenarioView.mapPropsToState(props);
        this.selectedComponents = {};
    }

    UNSAFE_componentWillReceiveProps(props: Props) {
        this.setState(ScenarioView.mapPropsToState(props));
        this.selectedComponents = {};
    }

    private selectedComponents: _.Dictionary<GetComponentModel>;

    private static reduceComponents = (scenarioComponents: GetScenarioComponentModel[]): _.Dictionary<GetScenarioComponentModel> =>
        scenarioComponents
            .reduce<_.Dictionary<GetScenarioComponentModel>>((result, current) => ({ ...result, [current.id]: current }), {});

    private onComponentUpdated = (component: GetScenarioComponentModel): void => this.setState({
        activeComponents: { ...this.state.activeComponents, [component.id]: component }
    });

    private onComponentSelected = (componentType: ComponentType): (components: GetComponentModel[]) => void => {
        return (components: GetComponentModel[]): void => {
            this.selectedComponents = _.omitBy(this.selectedComponents, x => x.type === componentType);
            components.forEach(component => this.selectedComponents[component.id] = component);
        }
    }

    private settingsCallbacks: SettingsCallbacks = {
        onProductWeightChanged: (errorMessage: string | JSX.Element, value: string | undefined): void => {
            if (errorMessage) return;
            if (value === undefined) return;
            this.setState({
                product: { ...this.state.product, weight: Number(value) }
            })
        },
        onProductQuantityChanged: (errorMessage: string | JSX.Element, value: string | undefined): void => {
            if (errorMessage) return;
            if (value === undefined) return;
            this.setState({
                product: { ...this.state.product, quantity: Number(value) }
            });
        },
        onProductCostChanged: (errorMessage: string | JSX.Element, value: string | undefined): void => {
            if (errorMessage) return;
            if (value === undefined) return;
            this.setState({
                product: { ...this.state.product, cost: Number(value) }
            });
        },
        onProductLifespanChanged: (errorMessage: string | JSX.Element, value: string | undefined): void => {
            if (errorMessage) return;
            if (value === undefined) return;
            this.setState({
                product: { ...this.state.product, lifespan: Number(value) }
            });
        },
        onCurrentEmissionCostChanged: (value: string | undefined): void => {
            if (value === undefined) return;
            this.setState({
                emissionCosts: { ...this.state.emissionCosts, current: Number(value) / 1000 }
            });
        },
        onFutureEmissionCostChanged: (value: string | undefined): void => {
            if (value === undefined) return;
            this.setState({
                emissionCosts: { ...this.state.emissionCosts, future: Number(value) / 1000 }
            });
        },
        onSequestrationPriceChanged: (value: string | undefined): void => {
            if (value === undefined) return;
            this.setState({
                sequestration: { ...this.state.sequestration, price: Number(value) / 1000 }
            });
        },
        onSequestrationPricingModelChanged: (event: FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined, index?: number | undefined): void => {
            if (option === undefined) return;
            this.setState({ 
                sequestration: { ...this.state.sequestration, pricingModel: option.data }
            });
        },
        isNaNError: (value: string): string => isNaN(Number(value)) ? "Enter a number" : ""
    };

    private onComponentClicked = (component: GetComponentModel) => {
        if (Object.keys(this.state.activeComponents).includes(component.id)) return;
        this.setState({ activeComponents: { ...this.state.activeComponents, [component.id]: { ...component, defaultEmissionId: component.emissions.find(x => x.source === ComponentEmissionSource.NonRenewable)!.id, quantity: 0 } } });
    }

    private onSelectorClosed = (componentType: ComponentType): (ev?: React.SyntheticEvent<HTMLElement>) => void => {
        return (ev?: React.SyntheticEvent<HTMLElement>) => {
            this.setState({ componentSelectorState: { ...this.state.componentSelectorState, [componentType]: { isOpen: false } } });
        };
    }

    private onOptionSelected = (option: GetScenarioOptionModel) => {
        let materials = this.mergeOptionComponents(option, ComponentType.Material);
        let utilities = this.mergeOptionComponents(option, ComponentType.Utility);
        let transport = this.mergeOptionComponents(option, ComponentType.Transport);
        let newComponents = ScenarioView.reduceComponents(materials.concat(utilities).concat(transport));
        const product: GetProductModel = {
            cost: option.productCost,
            lifespan: option.productLifespan,
            quantity: option.productQuantity,
            weight: option.productWeight
        };
        this.setState({ activeComponents: newComponents, componentRenderKey: uuidv4(), product: product, emissionCosts: { current: option.currentEmissionCost, future: option.futureEmissionCost }, sequestration: { price: option.sequestrationPrice, pricingModel: option.sequestrationPricingModel } });
    }

    private mergeOptionComponents = (option: GetScenarioOptionModel, componentType: ComponentType) => {
        let optionComponents = option.components.filter(x => x.type === componentType);
        let stateComponents = _.filter(this.state.activeComponents, x => x.type === componentType);
        return optionComponents.length === 0 ? stateComponents : optionComponents;
    }

    private onShareDialogDismiss = (ev?: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ shareDialog: { hidden: true } });
    }

    render() {
        return (
            <Grid.Row>
                <Grid.Col md={12}>
                    <Panel>
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <Text as="h1" block={true} variant="xxLarge" style={{ marginTop: 0 }}>{this.props.scenario.name}</Text>
                                <Text block={true} >{this.props.scenario.description}</Text>
                            </Grid.Col>
                        </Grid.Row>
                        <Separator />
                        <Grid.Col md={12} style={{ height: 15 }} />
                        <Grid.Row>
                            <Grid.Col xl={4} lg={6} md={12}>
                                <Text as="h2" variant="large" block={true} style={{ textAlign: "center" }}>CO₂ Emissions <InfoTooltipView text="As part of your product manufacturing, Carbon Dioxide (or its equivalents) is produced. This means emission pricing increasingly impacts your input costs. Here you can see the proportional impact of your manufacturing inputs on the products carbon footprint. Figures for these calculations are derived fron the UK Government's 2019 Emissions Conversion Factors data." /></Text>
                                <div id={InstructionTarget[InstructionTarget.Co2ContributionChart]}>
                                    <Co2ContributionChart components={this.state.activeComponents} product={this.state.product} />
                                </div>
                            </Grid.Col>
                            <Grid.Col xl={4} lg={6} md={12}>
                                <Text as="h2" variant="large" block={true} style={{ textAlign: "center" }}>Sequestered Carbon <InfoTooltipView text="The materials used as part of your product may sequester CO2 within them. Carbon sequestration is already being funded through certified schemes such as Verra and Gold Standard. This tool shows the effects of upcoming policies that could reward the use of materials that sequester carbon during a product's lifetime." /></Text>
                                <div id={InstructionTarget[InstructionTarget.CarbonContentChart]}>
                                    <CarbonContentChart components={this.state.activeComponents} product={this.state.product} />
                                </div>
                            </Grid.Col>
                            <Grid.Col xl={4} lg={12}>
                                <Grid.Row>
                                    <Grid.Col lg={7}>
                                        <Text as="h2" variant="large" block={true} style={{ textAlign: "right" }}>CO₂ Cost <InfoTooltipView text="Emission prices and (we believe) Sequestration prices are projected to increase year-on-year to achieve carbon drawdown. Here, we show you how your cost base may go up due to emissions pricing or go down due to sequestration pricing. You can mitigate risk by using shadow pricing techniques internally to help your organisation prepare for a carbon driven economy." /></Text>
                                    </Grid.Col>
                                    <Grid.Col lg={5} style={{ textAlign: "right" }}>
                                        <ActionButton style={{ height: "50px" }} iconProps={{ iconName: "Settings" }} onClick={this.openSettingsMenu}>Settings</ActionButton>
                                    </Grid.Col>
                                </Grid.Row>
                                <div id={InstructionTarget[InstructionTarget.Co2CostChart]}>
                                    <Co2CostChart sequestration={{...this.state.sequestration, permanentCutoff: this.permanentSequestrationCutoff}} components={this.state.activeComponents} product={this.state.product} emissionCosts={this.state.emissionCosts} />
                                </div>
                            </Grid.Col>
                        </Grid.Row>
                    </Panel>
                </Grid.Col>
                <Grid.Col md={12} style={{ height: 15 }} />
                <ScenarioOptionsView scenarioOptions={this.props.scenario.options} onOptionSelected={this.onOptionSelected} />
                <Grid.Col md={12}>
                    <Panel>
                        <Grid.Row>
                            <Grid.Col md={12}>
                                <CommandBar items={this.leftCommands} farItems={this.rightCommands} />
                            </Grid.Col>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Col xl={4} lg={6} sm={12} style={{ paddingRight: 0 }}>
                                <div id={InstructionTarget[InstructionTarget.MaterialsList]}>
                                    <MaterialsListContainer componentRenderKey={this.state.componentRenderKey} activeComponents={_.filter(this.state.activeComponents, x => x.type === ComponentType.Material)} onComponentUpdated={this.onComponentUpdated} onComponentSelected={this.onComponentSelected(ComponentType.Material)} />
                                </div>
                            </Grid.Col>
                            <Grid.Col xl={4} lg={6} sm={12} style={{ paddingRight: 0 }}>
                                <div id={InstructionTarget[InstructionTarget.UtilitiesList]}>
                                    <UtilitiesListContainer componentRenderKey={this.state.componentRenderKey} activeComponents={_.filter(this.state.activeComponents, x => x.type === ComponentType.Utility)} onComponentUpdated={this.onComponentUpdated} onComponentSelected={this.onComponentSelected(ComponentType.Utility)} />
                                </div>
                            </Grid.Col>
                            <Grid.Col xl={4} lg={12} sm={12}>
                                <div id={InstructionTarget[InstructionTarget.TransportList]}>
                                    <TransportListContainer componentRenderKey={this.state.componentRenderKey} activeComponents={_.filter(this.state.activeComponents, x => x.type === ComponentType.Transport)} onComponentUpdated={this.onComponentUpdated} onComponentSelected={this.onComponentSelected(ComponentType.Transport)} />
                                </div>
                            </Grid.Col>
                        </Grid.Row>
                    </Panel>
                </Grid.Col>
                <ShareDialog hidden={this.state.shareDialog.hidden} onDismiss={this.onShareDialogDismiss} emissionCosts={this.state.emissionCosts} components={_.values(this.state.activeComponents)} product={this.state.product} scenario={this.props.scenario} sequestration={this.state.sequestration}></ShareDialog>
                <ComponentSelector components={this.props.components.filter(x => x.type === ComponentType.Material)} isOpen={this.state.componentSelectorState[ComponentType.Material].isOpen} headerText="Materials" onComponentClicked={this.onComponentClicked} onClose={this.onSelectorClosed(ComponentType.Material)} />
                <ComponentSelector components={this.props.components.filter(x => x.type === ComponentType.Utility)} isOpen={this.state.componentSelectorState[ComponentType.Utility].isOpen} headerText="Utilities" onComponentClicked={this.onComponentClicked} onClose={this.onSelectorClosed(ComponentType.Utility)} />
                <ComponentSelector components={this.props.components.filter(x => x.type === ComponentType.Transport)} isOpen={this.state.componentSelectorState[ComponentType.Transport].isOpen} headerText="Transport" onComponentClicked={this.onComponentClicked} onClose={this.onSelectorClosed(ComponentType.Transport)} />
                <SettingsMenu sequestration={{...this.state.sequestration, permanentCutoff: this.permanentSequestrationCutoff}} product={this.state.product} callbacks={this.settingsCallbacks} emissionCosts={this.state.emissionCosts} isOpen={this.state.settingsMenuState.isOpen} onClose={this.onSettingsMenuClosed} />
                <Delay delay={500}>
                    <InstructionBubbles onChangeScenario={this.props.onChangeScenario} onOptionSelected={this.onOptionSelected} instructions={this.props.instructions} />
                </Delay>
            </Grid.Row >
        );
    }
}