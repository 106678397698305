import React from 'react';
import startAnimation from './data/start-animation.json';
import Lottie, { Options } from 'react-lottie';
import { GetScenarioModel } from '../../api/Contracts';
import * as Grid from 'react-grid-system';
import { Stack, Text } from 'office-ui-fabric-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/free-solid-svg-icons';
import Delay from '../../components/delay';
import './Scenarios.css';
import { Link } from 'react-router-dom';

type Props = {
    className?: string;
    scenarios: GetScenarioModel[];
}

export default class ScenariosView extends React.PureComponent<Props> {

    private options: Options = {
        autoplay: true,
        loop: false,
        animationData: startAnimation
    }

    private renderScenario = (scenario: GetScenarioModel) => {
        return (
            <Grid.Col lg={3} md={4} key={scenario.id}>
                <Link to={`/scenario/${scenario.id}`} style={{ textDecoration: "none" }}>
                    <Stack verticalAlign="center" horizontalAlign="center" style={{ textAlign: "center", background: scenario.icon.backgroundColor, padding: "15px", borderRadius: "20px", marginBottom: "30px" }}>
                        <FontAwesomeIcon icon={scenario.icon.name as Icons.IconName} size="10x" style={{ padding: "10px", color: scenario.icon.color }} />
                        <Text style={{ color: "#242F58" }} variant="xLarge">{scenario.name.toUpperCase()}</Text>
                    </Stack>
                </Link>
            </Grid.Col >
        );
    }

    render() {
        return (
            <div>
                <Lottie options={this.options} />
                <Delay delay={1500}>
                    <div style={{ padding: "50px" }}>
                        <Grid.Row className="fade-in">
                            {this.props.scenarios.map(this.renderScenario)}
                        </Grid.Row>
                    </div>
                </Delay>
            </div>
        );
    }
}