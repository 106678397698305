import React from 'react';
import { Dialog, DialogType, PrimaryButton, TextField, Callout, DirectionalHint } from 'office-ui-fabric-react';
import { CreateScenarioModel } from '../../api/Contracts';
import './ShareDialog.css'
import CopyToClipboard from 'react-copy-to-clipboard';
import { createScenarioAsync } from '../../api/Endpoints';
import buildUrl from 'build-url';
import 'location-origin';

type Props = {
    scenario: CreateScenarioModel;
    hidden: boolean;
    onDismiss: (ev?: React.MouseEvent<HTMLButtonElement>) => void;
}

type State = {
    shareLink: string;
    scenarioName: string;
}

type CalloutProps = {
    shareLink: string;
}

type CalloutState = {
    suffixText: string;
}

export default class ShareDialogView extends React.PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            shareLink: "",
            scenarioName: props.scenario.name
        };
    }

    private onClickAsync = async () => {
        const scenario: CreateScenarioModel = { ...this.props.scenario, name: this.state.scenarioName, description: "" };
        const response = await createScenarioAsync(scenario);
        const url = buildUrl(window.location.origin, { path: `#/scenario/${response.scenarioId}` });
        this.setState({ shareLink: url })
    }

    private onDismiss = (ev?: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onDismiss(ev);
        this.setState({ shareLink: "" });
    }

    private onGetErrorMessage = (value: string): string => value.trim().length === 0 ? "Enter a value" : "";

    private onNotifyValidationResult = (errorMessage: string | JSX.Element, value: string | undefined) => {
        if (errorMessage || value === undefined) return;
        this.setState({ scenarioName: value });
    }

    render() {
        if (this.props.hidden) return null;
        return (
            <Dialog hidden={this.props.hidden} dialogContentProps={{ title: "Share your Scenario", type: DialogType.largeHeader }} onDismiss={this.onDismiss}>
                <TextField label="Scenario Name" required={true} defaultValue={this.props.scenario.name} onGetErrorMessage={this.onGetErrorMessage} onNotifyValidationResult={this.onNotifyValidationResult} />
                <div style={{ height: "15px" }} />
                <PrimaryButton id="shareButton" style={{ display: "block", width: "100%" }} onClick={this.onClickAsync}>Get Sharing Link</PrimaryButton>
                <ShareCallout shareLink={this.state.shareLink} />
            </Dialog>
        );
    }
}

class ShareCallout extends React.PureComponent<CalloutProps, CalloutState> {

    constructor(props: CalloutProps) {
        super(props);
        this.state = {
            suffixText: this.copyText
        };
    }

    private copyText = "Copy";

    private onCopy = () => {
        this.setState({ suffixText: "Copied!" });
        setTimeout(() => this.setState({ suffixText: this.copyText }), 1000);
    }

    render() {
        if (!this.props.shareLink) return null;
        return (
            <Callout className="share-callout" target="#shareButton" directionalHint={DirectionalHint.bottomCenter}>
                <div style={{ height: "100%", padding: "15px" }}>
                    <CopyToClipboard text={this.props.shareLink} onCopy={this.onCopy}>
                        <span>
                            <TextField size={this.props.shareLink.length + 10} style={{ cursor: "pointer" }} suffix={this.state.suffixText} readOnly value={this.props.shareLink} />
                        </span>
                    </CopyToClipboard>
                </div>
            </Callout>
        );
    }
}