import React from 'react';
import CarbonContentChartView from './CarbonContentChartView';
import _ from 'lodash';
import { GetScenarioComponentModel, GetProductModel } from '../../../api/Contracts';

type Props = {
    components: _.Dictionary<GetScenarioComponentModel>;
    product: GetProductModel;
};

export default class CarbonContentChartContainer extends React.PureComponent<Props> {

    render() {
        return <CarbonContentChartView {...this.props} />;
    }

}