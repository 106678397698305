import React from 'react';
import { GetScenarioComponentModel, GetComponentModel, ComponentType } from '../../api/Contracts';
import { getComponentsByTypeAsync } from '../../api/Endpoints';
import ComponentListView, { ComponentCallbacks, ComponentListViewModel } from '../components-list';
import { IColumn, TextField, Text } from 'office-ui-fabric-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-common-types';

export type Params = {
    activeComponents: GetScenarioComponentModel[],
    onComponentUpdated: (component: GetScenarioComponentModel) => void;
    onComponentSelected: (components: GetComponentModel[]) => void;
    componentRenderKey: string;
}

type State = {
    transport: GetComponentModel[];
};

export default class TransportListContainer extends React.PureComponent<Params, State> {

    constructor(props: Params) {
        super(props);
        this.state = {
            transport: []
        }
    }

    private columnFactory = (componentRenderKey: string) => (callbacks: ComponentCallbacks): IColumn[] => {
        return [{
            key: "Transport",
            name: "Transport",
            minWidth: 80,
            onRender: (item: ComponentListViewModel) => <Text key={item.id}><FontAwesomeIcon icon={item.icon.name as IconName} style={{ color: item.icon.color }} /> {item.name}</Text>
        }, {
            key: "Distance",
            name: "Distance",
            minWidth: 95,
            onRender: (item: ComponentListViewModel) => <TextField key={componentRenderKey} onChange={callbacks.onQuantityChanged(item)} max={item.maxQuantity} step={item.maxQuantity / 100} defaultValue={item.quantity.toString()} suffix={item.unit} />
        }];
    }

    async componentDidMount() {
        try {
            let response = await getComponentsByTypeAsync(ComponentType.Transport);
            this.setState({ transport: response.components });
        } catch (ex) {
            console.log(ex);
        }
    }

    render() {
        if (this.state.transport.length === 0) return <div />;
        return <ComponentListView
            allComponents={this.state.transport}
            selectedComponents={this.props.activeComponents}
            columnFactory={this.columnFactory(this.props.componentRenderKey)}
            onComponentUpdated={this.props.onComponentUpdated}
            onComponentSelected={this.props.onComponentSelected}
            maxValue={10000} />
    }
}