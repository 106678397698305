import React from 'react';
import InformationPageView from '../../components/information-page';

type Props = {
    className?: string;
    title: string;
    paragraphs: string[];
}

export default class PrivacyPolicyView extends React.PureComponent<Props> {

    private title = "Privacy Policy";
    private paragraphs: string[] = [
        "This tool complies with the Cambridge Consultants <a href='https://www.cambridgeconsultants.com/legals/privacy-statement' target='_blank'>privacy policy</a>. This tool collects aggregated data on how people are using the tool. This is to improve tool usability and also generate crowdsourced aggregated insights into which aspects of carbon pricing our clients are most interested in, to help us service our clients needs.  If you choose to give us personally identifiable data via the feedback link, this information will be subject to the Cambridge Consultants <a href='https://www.cambridgeconsultants.com/legals/privacy-statement' target='_blank'>privacy policy</a>."
    ];

    render() {
        return <InformationPageView title={this.title} paragraphs={this.paragraphs} />;
    }
}