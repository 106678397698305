import { InstructionType, GetInstructionsResponse } from './Contracts';
//Run 'yarn generate-contracts' to update api contracts against staging environment api

import buildUrl from 'build-url';
import Axios from 'axios';
import axiosRetry, { exponentialDelay } from 'axios-retry';
import { GetScenariosResponse, GetScenarioResponse, GetComponentsResponse, ComponentType, CreateScenarioCommand, CreateScenarioResponse, CreateScenarioModel } from './Contracts';

axiosRetry(Axios, { retryDelay: exponentialDelay });

const apiUrl = process.env.REACT_APP_API_URL!;
const scenariosPath = "scenarios";
const componentsPath = "components";
const instructionsPath = "instructions";

export async function getScenariosAsync(): Promise<GetScenariosResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: scenariosPath
        });
        const response = await Axios.get<GetScenariosResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function createScenarioAsync(scenario: CreateScenarioModel): Promise<CreateScenarioResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: scenariosPath
        });
        const command: CreateScenarioCommand = { scenario: scenario };
        const response = await Axios.post<CreateScenarioResponse>(url, command);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function getScenarioByIdAsync(id: string): Promise<GetScenarioResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: `${scenariosPath}/${id}`
        });
        const response = await Axios.get<GetScenarioResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function getComponentsAsync(): Promise<GetComponentsResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: componentsPath
        });
        const response = await Axios.get<GetComponentsResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function getComponentsByTypeAsync(componentType: ComponentType): Promise<GetComponentsResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: `${componentsPath}/type/${componentType}`
        });
        const response = await Axios.get<GetComponentsResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export async function getInstructionsByTypeAsync(instructionType: InstructionType): Promise<GetInstructionsResponse> {
    try {
        const url = buildUrl(apiUrl, {
            path: `${instructionsPath}/type/${instructionType}`
        });
        const response = await Axios.get<GetInstructionsResponse>(url);
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};