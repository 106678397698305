import React from 'react';
import Co2ContributionChartView from './Co2ContributionChartView';
import _ from 'lodash';
import { GetScenarioComponentModel, GetProductModel } from '../../../api/Contracts';

type Props = {
    components: _.Dictionary<GetScenarioComponentModel>;
    product: GetProductModel;
};

export default class Co2CostChartContainer extends React.PureComponent<Props> {

    render() {
        return <Co2ContributionChartView {...this.props} />;
    }

}