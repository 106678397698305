import React from 'react';
import { TeachingBubble, ITeachingBubbleProps } from 'office-ui-fabric-react';

export type Props = {
    bubbleProps: ITeachingBubbleProps;
    hidden: boolean;
};

export default class TeachingBubbleView extends React.PureComponent<Props> {
    render() {
        if (this.props.hidden) return null;
        return (
            <TeachingBubble {...this.props.bubbleProps}>
                {this.props.children}
            </TeachingBubble>
        );
    }
}