import logo from './Logo.png';
import React from 'react';
import { IImageProps, Image } from 'office-ui-fabric-react';

export default class CambridgeConsultantsLogoView extends React.PureComponent<IImageProps> {

    render() {
        return (
            <a href="https://www.cambridgeconsultants.com" target="_blank" rel="noopener noreferrer">
                <Image src={logo} {... this.props} />
            </a>
        );
    }

}