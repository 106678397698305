import React from 'react';
import Lottie, { Options } from 'react-lottie';
import { Waypoint } from 'react-waypoint';
import { HashLink } from 'react-router-hash-link';

type Props = {
    className: string;
    options: Options;
    id: string;
    nextId?: string;
}
type State = {
    isStopped: boolean;
}
export default class HomeAnimationView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isStopped: true
        };
    }

    render() {
        if (this.props.nextId) {
            return (
                <div id={this.props.id} className={this.props.className}>
                    <Waypoint onEnter={() => this.setState({ isStopped: false })} bottomOffset="50%">
                        <div>
                            <Lottie options={this.props.options} />
                            <HashLink to={`#${this.props.nextId}`} smooth={true}>
                                <div className="scroll-arrow">
                                    <span /><span /><span />
                                </div>
                            </HashLink>
                        </div>
                    </Waypoint >
                </div>
            );
        }
        return (
            <div id={this.props.id} className={this.props.className}>
                <Waypoint onEnter={() => this.setState({ isStopped: false })} bottomOffset="50%">
                    <div>
                        <Lottie options={this.props.options} />
                    </div>
                </Waypoint >
            </div>
        );
    }
}
