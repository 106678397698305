import './Content.css';
import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router';
import Home from '../../routes/home';
import About from '../../routes/about';
import Scenario from '../../routes/scenario';
import { Params as ScenarioParams } from '../../routes/scenario/ScenarioContainer';
import PrivacyPolicy from '../../routes/privacy-policy';
import WhatsNext from '../../routes/whats-next';
import Scenarios from '../../routes/scenarios';
import Tutorial from '../../routes/tutorial';
import KeyMessages from '../../routes/key-messages';

interface Props {
    className?: string;
}

export default class ContentView extends React.PureComponent<Props> {

    render() {
        return (
            <div className="content">
                <Switch>
                    <Route exact path="/" component={Home} />
                    <Route path="/about" component={About} />
                    <Route path="/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/whats-next" component={WhatsNext} />
                    <Route path="/scenarios" component={Scenarios} />
                    <Route path="/scenario/:id" render={(props: RouteComponentProps<ScenarioParams>) => <Scenario key={props.match.params.id} {...props} />} />
                    <Route path="/tutorial" component={Tutorial} />
                    <Route path="/key-messages" component={KeyMessages} />
                </Switch>
            </div >
        );
    }

}