import React from 'react';
import { Options } from 'react-lottie';
import './Home.css';
import desktop1 from './data/desktop1.json';
import desktop2 from './data/desktop2.json';
import desktop3 from './data/desktop3.json';
import mobile1 from './data/mobile1.json';
import mobile2 from './data/mobile2.json';
import mobile3 from './data/mobile3.json';
import Animation from '../../components/home-animation';
import * as Grid from 'react-grid-system';

type Props = {
    className?: string;
}

export default class HomeView extends React.PureComponent<Props> {
    private options: Options = {
        autoplay: false,
        loop: false,
        animationData: undefined
    }
    render() {
        return (
            <Grid.ScreenClassRender render={(screenClass: string) => (
                <div className={`animations ${screenClass}`}>
                    <Grid.Visible xl>
                        <Animation id="0e9ccb87-1149-43b1-a363-10de3c96b02c" nextId="2c56edd6-efbf-4bf7-9d95-c7e6c75a104b" options={{ ...this.options, autoplay: true, animationData: desktop1 }} className="animation" />
                        <Animation id="2c56edd6-efbf-4bf7-9d95-c7e6c75a104b" nextId="4afbde03-951b-45b4-8145-4332f11a679a" options={{ ...this.options, animationData: desktop2 }} className="animation" />
                        <Animation id="4afbde03-951b-45b4-8145-4332f11a679a" options={{ ...this.options, animationData: desktop3 }} className="animation" />
                    </Grid.Visible>
                    <Grid.Visible lg md sm xs>
                        <Animation id="39e394a2-3529-47c3-a2e7-a49382d661a6" nextId="d17f58ae-8188-4926-91d9-01b24a40780c" options={{ ...this.options, autoplay: true, animationData: mobile1 }} className="animation" />
                        <Animation id="d17f58ae-8188-4926-91d9-01b24a40780c" nextId="7968e344-b5ca-46ba-9e24-4efdb07551cf" options={{ ...this.options, animationData: mobile2 }} className="animation" />
                        <Animation id="7968e344-b5ca-46ba-9e24-4efdb07551cf" options={{ ...this.options, animationData: mobile3 }} className="animation" />
                    </Grid.Visible>
                </div>
            )
            } />
        );
    }

}