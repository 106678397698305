import React, { FormEvent } from "react";
import { DetailsList, IColumn, Selection, ISelection } from 'office-ui-fabric-react';
import './ComponentsList.css';
import { GetScenarioComponentModel, GetComponentModel, ComponentEmissionSource } from "../../api/Contracts";
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export type ComponentListViewModel = GetScenarioComponentModel & {
    maxQuantity: number;
    active: boolean;
    valueFormat: (value: number) => string;
    renderKey: string;
}

type Props = {
    allComponents: GetComponentModel[];
    selectedComponents: GetScenarioComponentModel[];
    columnFactory: (callbacks: ComponentCallbacks) => IColumn[];
    onComponentUpdated: (component: GetScenarioComponentModel) => void;
    onComponentSelected: (components: GetComponentModel[]) => void;
    maxValue: number;
}

type State = {
    selection: ISelection;
}

export type ComponentCallbacks = {
    onEmissionsChanged: (item: ComponentListViewModel) => (event: React.MouseEvent<HTMLElement>, checked?: boolean) => void,
    onQuantityChanged: (item: ComponentListViewModel) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void
};

export default class ComponentsListView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = { selection: new Selection({ onSelectionChanged: this.onSelectionChanged }) }
    }

    private onComponentUpdated = _.debounce((updatedComponent: ComponentListViewModel) => this.props.onComponentUpdated({ ...updatedComponent }), 200);

    private onSelectionChanged = () => {
        let components = this.state.selection.getSelection().map(x => x as GetComponentModel);
        this.props.onComponentSelected(components);
    }

    private onEmissionsChanged = (viewModels: _.Dictionary<ComponentListViewModel>): (item: ComponentListViewModel) => (event: React.MouseEvent<HTMLElement>, checked?: boolean) => void => {
        return (item: ComponentListViewModel) => (event: React.MouseEvent<HTMLElement>, checked?: boolean) => {
            let updatedComponent = viewModels[item.id];
            let selectedEmissionSource = checked ? ComponentEmissionSource.Renewable : ComponentEmissionSource.NonRenewable;
            updatedComponent.defaultEmissionId = updatedComponent.emissions.find(x => x.source === selectedEmissionSource)!.id;
            viewModels = { ...viewModels, [updatedComponent.id]: updatedComponent };
            this.props.onComponentUpdated({ ...updatedComponent });
        }
    }

    private onQuantityChanged = (viewModels: _.Dictionary<ComponentListViewModel>): (item: ComponentListViewModel) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void => {
        return (item: ComponentListViewModel) => (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
            const value = Number(newValue);
            if (isNaN(value)) return;
            let updatedComponent = viewModels[item.id];
            updatedComponent.quantity = value;
            viewModels = { ...viewModels, [updatedComponent.id]: updatedComponent };
            this.onComponentUpdated(updatedComponent);
        }
    }

    private mapComponentsToViewModels = (components: GetComponentModel[], defaults: GetScenarioComponentModel[]): _.Dictionary<ComponentListViewModel> => {
        return components
            .map<ComponentListViewModel>(component => {
                let defaultComponent = defaults.find(y => y.id === component.id);
                return {
                    ...component,
                    quantity: defaultComponent === undefined ? 0 : defaultComponent.quantity,
                    defaultEmissionId: defaultComponent === undefined ? component.emissions.find(x => x.source === ComponentEmissionSource.NonRenewable)!.id : defaultComponent.defaultEmissionId,
                    maxQuantity: this.props.maxValue,
                    active: defaultComponent === undefined ? false : true,
                    valueFormat: (value: number) => `${value} ${component.unit}`,
                    renderKey: uuidv4()
                }
            })
            .filter(x => x.active)
            .reduce<_.Dictionary<ComponentListViewModel>>((result, current) => ({ ...result, [current.id]: current }), {})
    }

    render() {
        let viewModels = this.mapComponentsToViewModels(this.props.allComponents, this.props.selectedComponents);
        let columns = this.props.columnFactory({ onEmissionsChanged: this.onEmissionsChanged(viewModels), onQuantityChanged: this.onQuantityChanged(viewModels) });
        return <DetailsList items={_.sortBy(viewModels, x => x.name)} columns={columns} className="components" compact={true} selection={this.state.selection} />;
    }
}