import React from 'react';
import ScenarioView from './ScenarioView'
import { RouteComponentProps } from 'react-router';
import { GetScenarioModel, GetComponentModel } from '../../api/Contracts';
import { getScenarioByIdAsync, getComponentsAsync } from '../../api/Endpoints';

export type Params = {
    id: string;
}

type State = {
    scenario: GetScenarioModel | undefined;
    components: GetComponentModel[] | undefined;
};

export default class ScenarioContainer extends React.PureComponent<RouteComponentProps<Params>, State> {

    constructor(props: RouteComponentProps<Params>) {
        super(props);
        this.state = { scenario: undefined, components: undefined };
    }

    async componentDidMount() {
        try {
            const [scenarioResponse, componentResponse] = await Promise.all([
                getScenarioByIdAsync(this.props.match.params.id),
                getComponentsAsync()
            ]);
            this.setState({ scenario: scenarioResponse.scenario, components: componentResponse.components });
        } catch (ex) {
            console.error(ex);
        }
    }

    onChangeScenario = async (scenarioId: string): Promise<void> => {
        try {
            const response = await getScenarioByIdAsync(scenarioId);
            this.setState({ scenario: response.scenario });
        }
        catch (error) {
            console.error(error);
        }
    };

    render() {
        if (this.state.scenario === undefined || this.state.components === undefined) return null;
        return (
            <ScenarioView scenario={this.state.scenario} components={this.state.components} onChangeScenario={this.onChangeScenario} instructions={[]} />
        );
    }
}