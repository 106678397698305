import React from 'react';
import * as Grid from 'react-grid-system';
import './information-page.css';
import { v4 as uuidv4 } from 'uuid';

type Props = {
    className?: string;
    title: string;
    paragraphs: string[];
}

export default class InformationPageView extends React.PureComponent<Props> {
    render() {
        return (
            <Grid.Row className="information-page">
                <Grid.Col lg={6}>
                    <h1>{this.props.title.split(" ").map(word => <span key={uuidv4().toString()}>{word}<br /></span>)}</h1>
                </Grid.Col>
                <Grid.Col lg={5}>
                    {this.props.paragraphs.map(paragraph => <p key={uuidv4().toString()} dangerouslySetInnerHTML={{ __html: paragraph }}></p>)}
                </Grid.Col>
            </Grid.Row >
        );
    }
}