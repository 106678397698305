import React from 'react';
import TeachingBubble from '../teaching-bubble';
import * as _ from 'lodash';
import { DirectionalHint, ITeachingBubbleProps, Text, DefaultButton } from 'office-ui-fabric-react';
import { GetInstructionModel, InstructionTarget, GetScenarioOptionModel } from '../../api/Contracts';

type Props = {
    onChangeScenario: (scenarioId: string) => void;
    instructions: GetInstructionModel[];
    onOptionSelected: (option: GetScenarioOptionModel) => void;
};

type State = {
    bubbleState: _.Dictionary<{ hidden: boolean; }>;
}

export default class InstructionBubblesView extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            bubbleState: _.reduce(props.instructions, (result, current) => ({ ...result, [current.id]: { hidden: Object.keys(result).length > 0 } }), {})
        }
    }

    private onClick = (props: GetInstructionModel): () => void => {
        return () => {
            const bubbleState = { ...this.state.bubbleState };
            for (let key in bubbleState) {
                bubbleState[key].hidden = true;
            }
            if (props.nextInstructionId !== undefined) {
                bubbleState[props.nextInstructionId].hidden = false;
                const scenarioId = this.props.instructions.find(x => x.id === props.nextInstructionId)!.scenarioId;
                this.props.onChangeScenario(scenarioId);
            }
            this.setState({ bubbleState: bubbleState });
        };
    };

    render() {
        if (this.props.instructions.length === 0) return null;
        return (
            <div>
                {
                    _.sortBy(this.props.instructions, x => x.target).map(i => {
                        const buttonText = i.nextInstructionId === undefined ? "Done" : "Next";
                        const direction = i.nextInstructionId === undefined ? DirectionalHint.rightTopEdge : DirectionalHint.topCenter;
                        const bubbleProps: ITeachingBubbleProps = {
                            headline: i.title,
                            target: `#${InstructionTarget[i.target]}`,
                            primaryButtonProps: { children: buttonText, onClick: this.onClick(i) },
                            calloutProps: { directionalHint: direction }
                        }
                        return (
                            <TeachingBubble hidden={this.state.bubbleState[i.id].hidden} key={i.id} bubbleProps={bubbleProps}>
                                <Text>{i.content}</Text>
                                {i.options.map(x => <DefaultButton key={x.id} iconProps={{ iconName: x.icon.name }} style={{ width: "100%", marginTop: "15px" }} onClick={() => this.props.onOptionSelected(x)}>{x.name}</DefaultButton>)}
                            </TeachingBubble>
                        );
                    })
                }
            </div >
        );
    }
}