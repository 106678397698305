import React from 'react';
import ReactApexChart from 'react-apexcharts';
import './Co2CostChart.css';
import * as _ from 'lodash';
import { GetScenarioComponentModel, GetProductModel, ComponentType, SequestrationPricingModel } from '../../../api/Contracts';

type Props = {
    emissionCosts: {
        current: number;
        future: number;
    };
    components: _.Dictionary<GetScenarioComponentModel>;
    product: GetProductModel;
    sequestration: {
        pricingModel: SequestrationPricingModel;
        price: number;
        permanentCutoff: number;
    };
};

export default class Co2CostChartView extends React.PureComponent<Props> {

    private compoundFrequency: number = 1;
    private compoundTime: number = 30;
    private compoundInterval: number = 5;
    private inflationRate: number = 0.038;

    private getInterestRate = (n: number, t: number, A: number, P: number): number => n * ((A / P) ** (1 / (n * t)) - 1);

    private getValueWithInterest = (P: number, r: number, n: number, t: number): number => P * ((1 + (r / n)) ** (n * t));

    private getTargetCosts = (): { year: number, value: number }[] => {
        let P = this.props.emissionCosts.current;
        let A = this.props.emissionCosts.future;
        let r = this.getInterestRate(this.compoundFrequency, this.compoundTime, A, P);
        return this.getProjectedPrices(r, P);
    }

    private getProjectedPrices = (r: number, P: number): { year: number, value: number }[] =>
        _.range(0, this.compoundTime + this.compoundInterval, this.compoundInterval)
            .map(t => ({ year: 2020 + t, value: this.getValueWithInterest(P, r, this.compoundFrequency, t) }));

    private getEligibleSequesteredCo2 = (carbonMass: number): number => {
        const sequesteredMassYear = carbonMass * 1000;
        switch(this.props.sequestration.pricingModel) {
            case SequestrationPricingModel.Bern:
            case SequestrationPricingModel.Linear:
                return sequesteredMassYear * this.props.product.lifespan;
            case SequestrationPricingModel.Permanent:
                if(this.props.product.lifespan < this.props.sequestration.permanentCutoff) return 0;
                return sequesteredMassYear;
            case SequestrationPricingModel.None:
            default:
                return 0;
        }
    }

    render() {
        const futureCosts = this.getTargetCosts();
        const sequestrationPrices = this.getProjectedPrices(this.inflationRate, this.props.sequestration.price);
        const productEmissions = _.reduce(
            this.props.components,
            (result: number, component: GetScenarioComponentModel) => {
                switch (component.type) {
                    case ComponentType.Material:
                        return result + component.emissions.find(x => x.id === component.defaultEmissionId)!.value * this.props.product.weight * (component.quantity / 100);
                    case ComponentType.Transport:
                        return result + component.emissions.find(x => x.id === component.defaultEmissionId)!.value * this.props.product.weight * component.quantity;
                    default:
                        return result + component.emissions.find(x => x.id === component.defaultEmissionId)!.value * component.quantity / this.props.product.quantity;
                }
            },
            0
        );

        const productCosts = this.getProjectedPrices(this.inflationRate, this.props.product.cost)
            .reduce<_.NumericDictionary<number>>((result, current) => ({ ...result, [current.year]: current.value }), {});

        const carbonMass = _.reduce(
            _.filter(_.values(this.props.components), x => x.type === ComponentType.Material),
            (result: number, component: GetScenarioComponentModel) => result + (this.props.product.weight * (component.carbonContent * 3.67 / 1000) * (component.quantity / 100)),
            0
        );

        const sequesteredMass = this.getEligibleSequesteredCo2(carbonMass);

        const emissionCosts = futureCosts.map(price => {
            const sequestrationPrice = sequestrationPrices.find(x => x.year === price.year)!.value;
            return {
                x: price.year,
                y: ((price.value * productEmissions) - (sequesteredMass * sequestrationPrice)) / productCosts[price.year] * 100
            };
        });

        const series = [{
            name: "CO₂ Emission Cost Growth",
            data: emissionCosts
        }];

        const axisMin = Math.floor(Math.min(...emissionCosts.map(x => x.y)) / 2) * 2;
        const axisMax = Math.ceil(Math.max(...emissionCosts.map(x => x.y)) / 2) * 2;
        const options = {
            theme: {
                mode: 'light',
                palette: 'palette4'
            },
            chart: {
                toolbar: {
                    show: false
                }
            },
            tooltip: {
                y: [{
                    title: {
                        formatter: () => "CO₂eq Cost"
                    }
                }]
            },
            xaxis: {
                title: {
                    text: 'Year'
                }
            },
            yaxis: [{
                title: {
                    text: 'CO₂eq Cost (% of product cost)'
                },
                labels: {
                    formatter: (value: number) => `${Math.round(value * 10) / 10} % `,
                },
                min: axisMin > 0 ? 0 : axisMin,
                max: axisMax
            }]
        };

        return (
            <ReactApexChart options={options} series={series} height="auto" width="100%" />
        );
    }

}